export const components = {
  Advance: 'Anticipar',
  'Advance installments': 'Anticipar cuotas',
  Add: 'Adicionar',
  'Add billing card': 'Adicionar tarjeta de cobranza',
  Back: 'Volver',
  'Billing day successfully changed!':
    '¡La fecha de facturación se modificó correctamente!',
  Change: 'Cambiar',
  'Change billing day': 'Cambiar día de cobranza',
  'Download contract PDF': 'Descargar PDF del contrato',
  'Email successfully sent!': '¡Correo electrónico enviado con éxito!',
  'Hide details': 'Ocultar detalles',
  'Load more': 'Carga más',
  'Month day (between {{minimum}} and {{maximum}})':
    'Día del mes (entre {{minimum}} y {{maximum}})',
  Next: 'Próximo',
  'New billing card': 'Nueva tarjeta de cobranza',
  'New billing day': 'Nuevo día de cobranza',
  'Pay debts': 'Pagar deudas',
  'Payment total': 'Total a pagar',
  'Pendencies can possibly be charged on secondary cards':
    'Puede haber posibles cargos pendientes en tarjetas secundarias',
  'Provide the new day for us to carry out the charges':
    'Informe el nuevo día para realizar las cobranzas',
  "Provide your new card's data": 'Informe los datos de su nueva tarjeta',
  'See details': 'Ver detalles',
  'Send contract via e-mail': 'Enviar contrato por correo',
  'We made a transaction on your card in the amount of {{value}} that will be deducted from your next full installment':
    'Realizamos una transacción en tu tarjeta por un monto de {{value}} que se descontará de tu próxima cuota completa.',
  'Your card was successfully changed!':
    '¡Su tarjeta ha sido cambiada exitosamente!',
  'Your next charge will be on {{date}}': 'Su próximo cargo será el {{date}}',
  'Your registered payment methods': 'Métodos de pago registrados',
  '<0>Warning</0> The new card provided can be used in future charges of your account in case the main card is rejected':
    '<0>Advertencia:</0> La nueva tarjeta ingresada puede usarse para cargos futuros de este registro si se rechaza la transacción en la tarjeta principal.',
};
