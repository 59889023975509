export const components = {
  Buy: 'Comprar',
  Continue: 'Continuar',
  'Definitive hair removal': 'Depilação definitiva',
  Doubts: 'Dúvidas',
  'Do you have any doubts?': 'Ficou com alguma dúvida?',
  'Easy payment': 'Facilidade no pagamento',
  'Finish and secure my discount': 'Finalizar e garantir meu desconto',
  'Getting rid of your hairs is possible and is for real!':
    'Se livrar dos pelos é possível e é de verdade!',
  'Go to the store': 'Ir para a loja',
  'I want to buy now': 'Quero comprar agora',
  'I want to participate in the raffle': 'Quero participar do sorteio',
  'Improve your day to day': 'Melhore o seu dia a dia',
  'Laser hair removal with less pain and more results!':
    'Depilação a laser com menos dor e mais resultado!',
  'Make my register': 'Realizar meu cadastro',
  'Register to guarantee your benefit at Vialaser':
    'Faça seu cadastro para garantir seu beneficio na Vialaser',
  'Registered successfully!': 'Registrado com sucesso!',
  "Say goodbye to your hairs once and for all! Vialaser's technology is able to remove forever the hairs at the right growth phase Stay rid of concerns!":
    'Dê adeus de forma definitiva aos pelos! A tecnologia da Vialaser é capaz de eliminar para sempre os pelos que estão na fase correta do crescimento. Fique livre de preocupações!',
  'Talk to an attendant': 'Falar com uma atendente',
  'The informed e-mail is not valid!': 'O e-mail informado está inválido!',
  'You must inform the e-mail!': 'Voce precisa informar o e-mail!',
  'Your e-mail': 'Seu e-mail',
  'Your name and lastname': 'Seu nome e sobrenome',
  "We don't believe in gender distinction! Here at Vialaser, everyone is equal, and as such the prices consider only the areas, not the genders":
    'Não acreditamos na distinção de gênero! Na Vialaser, todo mundo é igual e, por isso, os preços são pensados apenas por área, nunca por gênero.',
};
