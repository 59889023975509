import { useCallback } from 'react';

import { useAuth } from 'modules/authentication/presentation/contexts';
import { HTTPError, UseCaseError } from 'shared/domain/errors';
import { useLocale, useToast } from 'shared/presentation/contexts';

import useRegisterError from './useRegisterError';

type TLocaleNamespace = Parameters<typeof useLocale>[0];

export default function useRequestErrorHandler(namespace: TLocaleNamespace) {
  const toast = useToast();
  const errorRegister = useRegisterError();
  const auth = useAuth();

  const { t } = useLocale(namespace);

  const handleError = useCallback(
    (error: unknown, registerErrorKey?: string) => {
      if (registerErrorKey) {
        errorRegister.register({
          key: registerErrorKey,
          error,
        });
      }

      if (error instanceof UseCaseError) {
        return toast.error(error.translateMessage(t));
      }

      if (error instanceof HTTPError && error.unauthorized) {
        return auth.logout();
      }

      if (error instanceof Error) return toast.error(error.message);

      return toast.error(t('Erro, algo inesperado aconteceu!'));
    },
    [errorRegister, toast, t, auth],
  );

  return handleError;
}
