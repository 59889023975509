import {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
} from 'react';

import { Spinner } from 'shared/presentation/components/atoms';
import { TIconType } from 'shared/presentation/constants';
import { ITheme, useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

type TDefaultButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface IButtonProps {
  disabled?: TDefaultButtonProps['disabled'];
  onClick?: TDefaultButtonProps['onClick'];
  variant?: S.TButtonVariant;
  icon?: TIconType;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  outline?: boolean;
  hideTextWhenLoading?: boolean;
  className?: string;
  type?: TDefaultButtonProps['type'];
  children: string;
}

const SPINNER_COLORS: Record<
  NonNullable<IButtonProps['variant']>,
  (theme: ITheme) => string
> = {
  default: theme => theme.palette.text.main,
  error: theme => theme.palette.error.main,
  warning: theme => theme.palette.warning.main,
  primary: theme => theme.palette.primary.main,
  secondary: theme => theme.palette.secondary.main,
};

const getSpinnerColor = (
  variant: IButtonProps['variant'],
  outline: IButtonProps['outline'],
  theme: ITheme,
) => {
  if (!variant) return theme.palette.text.main;
  if (outline) return SPINNER_COLORS[variant](theme);

  return theme.palette.text.white;
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, IButtonProps> = (
  {
    disabled,
    onClick,
    variant = 'default',
    icon: Icon = Fragment,
    iconPosition,
    loading,
    hideTextWhenLoading,
    outline,
    className,
    type,
    children,
    ...rest
  },
  ref,
) => {
  const shouldHideText = loading && hideTextWhenLoading;

  const { theme } = useTheme();

  const buttonChildren = [
    loading ? (
      <Spinner
        key="spinner"
        color={getSpinnerColor(variant, outline, theme)}
        size={1}
      />
    ) : (
      <Icon key="icon" />
    ),
    !shouldHideText && <Fragment key="children">{children}</Fragment>,
  ];

  return (
    <S.Button
      disabled={loading || disabled}
      onClick={onClick}
      variant={variant}
      className={className}
      type={type}
      outline={outline}
      ref={ref}
      loading={loading}
      {...rest}
    >
      {iconPosition === 'right' ? buttonChildren.reverse() : buttonChildren}
    </S.Button>
  );
};

export default forwardRef(Button);
