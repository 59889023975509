export const signup = {
  'Already have an account? <1>Sign in!</1>':
    'Já tem uma conta? <1>Faça login!</1>',
  'And live your life free of hairs!': 'E viva sua vida livre de pelinhos!',
  'Confirm password': 'Confirmar senha',
  Email: 'E-mail',
  Fullname: 'Nome completo',
  'I accept the <1>Terms and conditions</1> and I authorize the use of my data according to the <3>Privacy policy</3>':
    'Aceito os <1>Termos e condições</1> e autorizo o uso de meus dados de acordo com a <3>Declaração de privacidade</3>.',
  Medium: 'Média',
  Password: 'Senha',
  Phone: 'Telefone',
  'Please, inform a valid e-mail': 'Por favor, informe um email válido',
  'Please, inform a valid name': 'Por favor, informe um nome válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Or register with your social networks':
    'Ou cadastre-se com suas redes sociais',
  Register: 'Cadastrar',
  'Register yourself': 'Cadastre-se',
  'Registered successfully!': 'Registrado com sucesso!',
  "The passwords don't match": 'As senhas não coincidem',
  'This field is required': 'Este campo é obrigatório',
  'Too weak': 'Muito fraca',
  'Sign up': 'Cadastro',
  'Sign up with Google': 'Cadastrar com Google',
  Strong: 'Forte',
  Weak: 'Fraca',
};
