import { lazy } from 'react';

import type { TRoute } from 'shared/presentation/modules/definitions';

const ChangeReparcelingCard = lazy(
  () => import('modules/customer/presentation/pages/ChangeReparcelingCard'),
);
const ContractCheckout = lazy(
  () => import('modules/customer/presentation/pages/ContractCheckout'),
);
const Contract = lazy(
  () => import('modules/customer/presentation/pages/Contract'),
);
const EmailUpdate = lazy(
  () => import('modules/customer/presentation/pages/EmailUpdate'),
);
const PhoneUpdate = lazy(
  () => import('modules/customer/presentation/pages/PhoneUpdate'),
);
const GeneralPendingIssues = lazy(
  () => import('modules/customer/presentation/pages/GeneralPendingIssues'),
);
const PendingIssues = lazy(
  () => import('modules/customer/presentation/pages/PendingIssues'),
);
const Reparceling = lazy(
  () => import('modules/customer/presentation/pages/Reparceling'),
);
const Success = lazy(
  () => import('modules/customer/presentation/pages/Success'),
);

const customerRoutes: TRoute[] = [
  {
    type: 'route',
    path: '/cliente/reparcelamento/troca-cartao/:hash',
    component: ChangeReparcelingCard,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/cliente/contratos/pagamento',
    component: ContractCheckout,
  },
  {
    type: 'route',
    path: '/cliente/reparcelamento/:hash',
    component: Reparceling,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/cliente/sucesso',
    component: Success,
  },
  {
    type: 'route',
    path: '/cliente/contrato/:id',
    component: Contract,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/cliente/pendencias/:contractId',
    component: PendingIssues,
  },
  {
    type: 'route',
    path: '/cliente/pendencias',
    component: GeneralPendingIssues,
  },
  {
    type: 'route',
    path: '/cliente/alteracao-email/:hash',
    component: EmailUpdate,
  },
  {
    type: 'route',
    path: '/cliente/alteracao-celular/:hash',
    component: PhoneUpdate,
  },
];

export default customerRoutes;
