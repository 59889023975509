export const schedules = {
  'Are you sure you want to cancel the session?': '?',
  'Cancel session': '',
  '{{date}} at {{time}}': '',
  Finished: '',
  'Inform the reason for the cancellation': '',
  'Next Sessions': '',
  Reschedule: '',
  Schedule: '',
  Schedules: '',
  'Schedules History': '',
  Today: '',
  Tomorrow: '',
  'View your schedules, reeschedule or appoint your new sessions': '',
  "You don't have any past schedules": '',
};
