import axios from 'axios';

import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IStorageProvider from 'shared/domain/providers/IStorageProvider';

export default class SyncUserSessionUseCase {
  constructor(
    private storageProvider: IStorageProvider,
    private httpProvider: IHTTPProvider,
  ) {}

  public async execute() {
    const authentication = this.storageProvider.get('AUTHENTICATION');

    if (!authentication) {
      // TODO: Remove axios mention when the entire app starts using httpProvider
      delete axios.defaults.headers.common.Authorization;
      this.httpProvider.setHeader('AUTHENTICATION', null);

      return null;
    }

    // TODO: Remove axios mention when the entire app starts using httpProvider
    axios.defaults.headers.common.Authorization = `Bearer ${authentication.auth.accessToken}`;
    this.httpProvider.setHeader(
      'AUTHENTICATION',
      `Bearer ${authentication.auth.accessToken}`,
    );

    return authentication.session;
  }
}
