export const components = {
  Buy: 'Comprar',
  Continue: 'Continuar',
  'Definitive hair removal': 'Depilación definitiva',
  Doubts: 'Dudas',
  'Do you have any doubts?': '¿Has quedado con alguna duda?',
  'Easy payment': 'Facilidad de pago',
  'Finish and secure my discount': 'Finalizar y asegurar mi descuento',
  'Getting rid of your hairs is possible and is for real!':
    '¡Sí, librarse de los vellos es posible y es de verdad!',
  'Go to the store': 'Ve a la tienda',
  'I want to buy now': 'Quiero comprar ahora',
  'I want to participate in the raffle': 'Quiero participar del sorteo',
  'Improve your day to day': 'Mejore su día a día',
  'Laser hair removal with less pain and more results!':
    '¡Depilación láser con menos dolor y más resultados!',
  'Make my register': 'Completar mi registro',
  'Register to guarantee your benefit at Vialaser':
    'Registra tu cuenta para asegurar tu beneficio en Vialaser',
  'Registered successfully!': '¡Registrado con éxito!',
  "Say goodbye to your hairs once and for all! Vialaser's technology is able to remove forever the hairs at the right growth phase Stay rid of concerns!":
    '¡Despídete del vello para siempre! La tecnología de Vialaser es capaz de eliminar para siempre los vellos que están en la fase correcta de crecimiento. ¡Quede libre de preocupaciones!',
  'Talk to an attendant': 'Hablar con una representante',
  'The informed e-mail is not valid!':
    '¡El correo electrónico ingresado no es válido!',
  'You must inform the e-mail!': '¡Necesitas informar el correo electrónico!',
  'Your e-mail': 'Su email',
  'Your name and lastname': 'Tu nombre y apellido',
  "We don't believe in gender distinction! Here at Vialaser, everyone is equal, and as such the prices consider only the areas, not the genders":
    '¡No creemos en la distinción de género! Con Vialaser, todo el mundo es igual, es por eso que los precios son pensados por área, no por género.',
};
