import { v4 as uuid } from 'uuid';

import { makeStorageProvider } from 'shared/infra/providers';

import Tracking from '../services/Tracking';

export function trackData(page, eventType, options = {}) {
  let sessionId;

  try {
    sessionId = localStorage.getItem('@vialaser:sessionId');
  } catch {
    const id = uuid();
    sessionId = id;
    localStorage.setItem('@vialaser:sessionId', id);
  }

  const userId = makeStorageProvider().get('AUTHENTICATION')?.session.id;

  const data = {
    session_id: sessionId,
    device: navigator.userAgent,
    user_id: userId,
    source: 'VIALASER',
    route: page,
    event: eventType,
    event_type: eventType,
    env: process.env.REACT_APP_ENV,
    event_data: {
      ...options,
    },
  };

  if (process.env.REACT_APP_ENV === 'production') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);

    Tracking.dataInfoTrack(data);
  } else {
    console.log('==== TRACKING DATA ====', data);
  }
}

export function trackGAEvent(event, options = {}) {
  console.group('Logs do registro de Data Layer: ');
  console.log('- Ambiente atual: ', process.env.NODE_ENV);

  if (process.env.NODE_ENV === 'production') {
    const dataLayerArgs = { ...options, event };
    console.log('- Dados do Data Layer: ', dataLayerArgs);

    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerArgs);

      console.log('- Data Layer registrado!');
    } catch (error) {
      console.log('- Data Layer não registrado!');
      console.log('- Error: ', error);
    }
  }

  console.groupEnd();
}

export function trackFBEvent(event, options = {}) {
  if (process.env.NODE_ENV === 'production' && typeof fbq === 'function') {
    // MAGICA, NÂO TOCA
    // eslint-disable-next-line no-undef
    fbq('track', event, options);
  }
}
