export const schedules = {
  'Are you sure you want to cancel the session?':
    'Tem certeza que deseja cancelar a sessão?',
  'Cancel session': 'Cancelar sessão',
  '{{date}} at {{time}}': '{{date}} às {{time}}',
  Finished: 'Concluida',
  'Inform the reason for the cancellation': 'Informe o motivo do cancelamento',
  'Next Sessions': 'Próximas sessões',
  Reschedule: 'Reagendar',
  Schedule: 'Agendar',
  Schedules: 'Agendamentos',
  'Schedules History': 'Histórico de Agendamentos',
  Today: 'Hoje',
  Tomorrow: 'Amanhã',
  'View your schedules, reeschedule or appoint your new sessions':
    'Visualize seus agendamentos, reagende ou marque sua próxima sessão',
  "You don't have any past schedules":
    'Você não tem nenhum agendamento anterior',
};
